var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "organ-post-p" },
    [
      _c("layout", {
        ref: "layoutMenu",
        attrs: {
          "menu-data-list": _vm.menuDataList,
          "selected-key": _vm.selectedKey,
          "del-msg-info": {
            title: "删除岗位分类",
            msg: "岗位分类及岗位?",
          },
        },
        on: {
          "update:selectedKey": function ($event) {
            _vm.selectedKey = $event
          },
          "update:selected-key": function ($event) {
            _vm.selectedKey = $event
          },
          "menu-select-change": _vm.menuSelectChange,
          "menu-action-edit": _vm.menuActionEdit,
          "menu-action-del": _vm.menuActionDel,
        },
        scopedSlots: _vm._u([
          {
            key: "leftTop",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "left-top-p", on: { click: _vm.handleAdd } },
                  [
                    _c("svg", { staticClass: "iconpark-icon post-add-icon" }, [
                      _c("use", { attrs: { href: "#add-one" } }),
                    ]),
                    _c("span", [_vm._v("新建岗位分类")]),
                  ]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "default",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "organ-post-tb-p" },
                  [
                    _c("PostTabel", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.selectedKey != "",
                          expression: "selectedKey != ''",
                        },
                      ],
                      ref: "postTabel",
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("postClassForm", {
        ref: "postClassForm",
        on: { reload: _vm.initMenuData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }