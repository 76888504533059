var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "post-table" },
    [
      _c("div", { staticClass: "post-toolbar" }, [
        _c(
          "div",
          { staticClass: "post-tb-left" },
          [
            _c(
              "a-button",
              { attrs: { prefix: "add-one" }, on: { click: _vm.handleAdd } },
              [_c("span", [_vm._v("新建岗位")])]
            ),
            _c(
              "a-button",
              {
                attrs: {
                  prefix: "reduce-one",
                  disabled: !_vm.selectedRowKeys.length,
                },
                on: { click: _vm.handleDel },
              },
              [_c("span", [_vm._v("批量删除岗位")])]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "post-tb-right" },
          [
            _c("a-input-search", {
              staticClass: "post-tb-search",
              attrs: { placeholder: "搜索" },
              on: { search: _vm.onSearch },
              model: {
                value: _vm.currentPostName,
                callback: function ($$v) {
                  _vm.currentPostName = $$v
                },
                expression: "currentPostName",
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "post-statistic" }, [
        _c("div", { staticClass: "statistic-p" }, [
          _vm._v("岗位总数：" + _vm._s(_vm.postCount)),
        ]),
        _c("div", { staticClass: "statistic-p" }, [
          _vm._v("在职总人数：" + _vm._s(_vm.incumbencyCount)),
        ]),
      ]),
      _c("custom-table", {
        attrs: {
          columns: _vm.columns,
          "data-source": _vm.dataSource,
          "row-key": "postId",
          "table-ref": ".post-table",
          pagination: {
            current: _vm.pager.pageNum,
            pageSize: _vm.pager.pageSize,
            total: _vm.total,
            showTotal: function (total) {
              return "共 " + total + " 条数据"
            },
            showQuickJumper: true,
            showSizeChanger: true,
          },
          loading: _vm.loading,
          "row-selection": {
            selectedRowKeys: _vm.selectedRowKeys,
            columnWidth: 25,
            onChange: _vm.onSelectChange,
          },
          "selection-mode": _vm.selectionMode,
        },
        on: { change: _vm.loadDataList },
        scopedSlots: _vm._u([
          {
            key: "operation",
            fn: function (ref) {
              var scope = ref.scope
              return [
                _c(
                  "span",
                  { staticClass: "operation-btn-box" },
                  [
                    _c(
                      "a-button",
                      {
                        attrs: { type: "link" },
                        on: {
                          click: function ($event) {
                            return _vm.edit(scope.record)
                          },
                        },
                      },
                      [_vm._v(" 编辑 ")]
                    ),
                    _c(
                      "a-button",
                      {
                        staticClass: "delete",
                        attrs: { type: "link" },
                        on: {
                          "~click": function ($event) {
                            return _vm.delSingle(scope.record)
                          },
                        },
                      },
                      [_vm._v(" 删除 ")]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c("postForm", { ref: "postForm", on: { reload: _vm.postFormReload } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }